<template>
  <div class="enterprise-list">
    <div class="screen clearfix">
      <div class="item">
        <span>企业名称</span>
        <div class="box">
          <el-input v-model="screen.company" placeholder="输入企业名称" style="width: 240px"></el-input>
        </div>
      </div>
      <div class="item">
        <span>所在地区</span>
        <div class="box">
          <el-cascader
              placeholder="选择所在地区" style="width: 180px;"
              v-model="addressData"
              :options="dictData.addressData"
              :props="{ label: 'name', value: 'id', multiple: true }"
              ref="cascader"
              collapse-tags
              @change="handleChange"></el-cascader>
        </div>
      </div>
      <div class="item">
        <span>所属网格</span>
        <div class="box">
          <el-select v-model="screen.grid_id" filterable clearable :popper-append-to-body="false" placeholder="全部"
                     style="width: 180px">
            <el-option
                v-for="item in dictData.gridOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="item">
        <span>注册时间</span>
        <div class="box">
          <el-date-picker
              v-model="registrationTime"
              class="daterange"
              type="daterange"
              range-separator="至"
              value-format="yyyy-MM-dd"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              style="width: 220px"
              @change="handleChangeDate">
          </el-date-picker>
        </div>
      </div>
      <div class="item">
        <span>问诊状态</span>
        <div class="box">
          <el-select v-model="screen.diagnose_status" :popper-append-to-body="false" placeholder="全部"
                     style="width: 140px">
            <el-option
                v-for="item in dictData.consultationStatusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <span class="button inquire" @click="search">查询</span>
      <span class="button" @click="resetQuery">重置</span>
    </div>
    <el-table
        ref="multipleTable"
        :data="tableData.data"
        :border="true"
        v-loading="tableData.loading"
        style="width: 100%"
        @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="60" align="center"></el-table-column>
      <el-table-column prop="id" label="ID" width="70"></el-table-column>
      <el-table-column prop="company" label="企业名称" min-width="100%"></el-table-column>
      <el-table-column prop="company_no" label="企业编号"></el-table-column>
      <el-table-column prop="username" label="用户名"></el-table-column>
      <el-table-column prop="areaname" label="所在地区"></el-table-column>
      <el-table-column prop="grid_name" label="所属网格" min-width="100%"></el-table-column>
      <el-table-column prop="addtime" label="注册时间"></el-table-column>
      <el-table-column prop="source" label="企业来源">
        <template slot-scope="scope">
          <span> {{ scope.row.source == 1 ? '自主注册' : '问诊导入' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="business_manager_name" label="商企经理" min-width="100%"></el-table-column>
      <el-table-column label="问诊状态" width="100" align="center">
        <template slot-scope="scope">
          <span
              :class="[scope.row.diagnose_status === 1 ? 'color-red' : scope.row.diagnose_status === 3 ? 'color-green' : 'color-blue']">
            {{ getDiagnoseText(scope.row.diagnose_status) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="id" fixed="right" label="操作" width="150">
        <template slot-scope="scope">
			<div class="flex flex-col-center flex-row-between">
			  <router-link
				  v-if="scope.row.diagnose_status !== 1"
				  :to="{name: 'companyImg', query: {id: scope.row.id, is_status: scope.row.diagnose_status === 3 ? 1 : 0 }}">
				  <span class="color-blue">详情</span>
			  </router-link>
			  <span class="color-blue touch-hover" v-if="scope.row.diagnose_status === 1 && $store.getters.isItAnAdministrator" @click="openInvite(scope.row)">邀请问诊</span>
			  <span class="color-blue touch-hover" v-if="scope.row.diagnose_status === 2 && $store.getters.isItAnAdministrator" @click="editList(scope)">数转诊断</span>
			  <span class="color-blue touch-hover" v-if="scope.row.diagnose_status === 3" @click="editList(scope, 'report')">查看报告</span>
			  <span class="color-blue touch-hover" @click="editList(scope, 'edit')" v-if="scope.row.diagnose_status!=1">编辑</span>
			</div>
        </template>
      </el-table-column>
    </el-table>
    <div class="operate">
      <div class="handle">
        <div class="all-select">
          <el-checkbox :indeterminate="tableData.isIndeterminate" v-model="tableData.allSelect" @change="toggleSelection()"></el-checkbox>
        </div>
        <div class="button" @click="toggleSelection()">全选</div>
        <div class="button" @click="openAllInvite">邀请问诊</div>
      </div>
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="screen.page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="screen.pageSize"
          layout="prev, pager, next, sizes"
          :total="tableData.total">
      </el-pagination>
    </div>
    <invite-popup :show="inviteData.show" :companyList="inviteData.data" @close="closeClick"></invite-popup>
  </div>
</template>

<script>
import {timeFormat} from "@/utils/index.js"
import invitePopup from "@/views/enterpriseList/components/invite-popup.vue"

const defaultQuery = {
  page: 1, // 请求页码
  pageSize: 10, // 每页显示条数
  company: '',
  start_time: null,
  end_time: null,
  areaid: null,
  diagnose_status: null,
  grid_id: null
}

export default {
  components: {
    invitePopup
  },
  data() {
    return {
      screen: {
        ...defaultQuery
      }, // 筛选条件
      tableData: {
        data: [], // 表格列表数据
        isIndeterminate: false, // 表格是否有选中但未全选
        allSelect: false, // 表格全选
        multipleSelection: [], // 表格选中列表
        currentPage: 1, // 表格当前页码
        total: 0, // 总条数
        loading: false, // 表格渲染loading
        ids: [], // 表格选中列表id值
      }, // 表格数据
      inviteData: {show: false, data: []},//是否显示邀请问诊弹窗
      addressData: [],
      registrationTime: [],
      dictData: {
        // 地区
        addressData: [],
        gridOptions: [],
        // 1:待问诊 2:待诊断 3:已诊断
        consultationStatusOptions: [
          {
            value: '1',
            label: '待问诊'
          },
          {
            value: '2',
            label: '待诊断'
          },
          {
            value: '3',
            label: '已诊断'
          }
        ]
      },
    }
  },
  mounted() {
    // this.initData();
    this.getDataList()
    // this.getAddressData();
    //获取网格列表
    this.$api.Grids().then(res => {
      this.$set(this.dictData, 'gridOptions', res.data)
    })

    //地区
    this.$api.area_tree_list().then(res => {
      this.$set(this.dictData, 'addressData', res.data)
    })
  },
  methods: {
    // initData() {
    // 	this.isLoading = true;
    // 	let data = {
    // 	  page: this.pageIndex,
    // 	  pageSize: this.pageSize,
    // 	  company: this.screen.name,
    // 	  start_time: this.screen.registrationTime[0]?timeFormat(this.screen.registrationTime[0], 'yyyy-mm-dd hh:MM:ss'):'',
    // 	  end_time: this.screen.registrationTime[1]?timeFormat(this.screen.registrationTime[1], 'yyyy-mm-dd hh:MM:ss'):'',
    // 	  areaid: this.screen.area.length>0?this.screen.area[this.screen.area.length-1]:'',
    // 	  diagnose_status: this.screen.consultationStatus,
    // 	  grid_id: this.screen.grid
    // 	}
    // 	this.$api.companyLists(data).then(res=>{
    // 		this.isLoading = false;
    // 		this.tableData = res.data.data;
    // 		this.total = res.data.count;
    // 	}).catch(()=>{
    // 		this.isLoading = false;
    // 	})
    // },
    // //查询
    // queryClick() {
    // 	this.pageIndex = 1;
    // 	this.initData();
    // },
    // //重置
    // restClick() {
    // 	this.screen.name = '';
    // 	this.screen.registrationTime = [];
    // 	this.screen.area = [];
    // 	this.screen.consultationStatus = '';
    // 	this.screen.grid = '';
    // 	this.pageIndex = 1;
    // 	this.initData();
    // },
    // //获取地区数据
    // getAddressData() {
    // 	this.$api.areaTreeList().then(res=>{
    // 		this.optionsAddress = res.data;
    // 	})
    // },
    //显示邀请问诊弹窗
    openInvite(e) {
      this.inviteData.data = [];
      this.inviteData.data.push(e.id);
      this.inviteData.show = true;
    },
    //全选邀请问诊
    openAllInvite() {
      if (this.inviteData.data.length == 0) return;
      this.inviteData.show = true;
    },
    //关闭
    closeClick() {
      this.inviteData.show = false;
    },
    //获取问诊状态
    getDiagnoseText(num) {
      if (num == 1) return '待问诊';
      if (num == 2) return '待诊断';
      if (num == 3) return '已诊断';
      if (num == 4) return '待问诊(复诊)';
      if (num == 5) return '待诊断(复诊)';
      if (num == 6) return '已诊断(复诊)';
    },
    handleSelectionChange(val) {
      // this.multipleSelection = val;
      // this.isIndeterminate = val.length > 0 && val.length < this.tableData.length;
      // this.allSelect = val.length === this.tableData.length ? true : false;
      this.inviteData.data = [];
      for (let i = 0; i < val.length; i++) {
        this.inviteData.data.push(val[i].id);
      }

      this.tableData.multipleSelection = val;
      this.tableData.ids = val.map(item => item.id);
      this.tableData.isIndeterminate = val.length > 0 && val.length < this.tableData.data.length;
      this.tableData.allSelect = val.length === this.tableData.data.length ? true : false;
    }, // 表格选中改变
    toggleSelection() {
      this.$refs.multipleTable.toggleAllSelection();
    }, // 表格全选
    // 单个星标点击
    handleStarChange(index) {
      this.tableData[index].star = !this.tableData[index].star;
    },
    handleSizeChange(val) {
      this.screen.pageSize = val;
      this.getDataList();
    }, // 分页pageSize改变
    handleCurrentChange(val) {
      this.screen.page = val;
      this.getDataList();
    }, // 分页currentPage改变
    /**
     * 查询
     */
    search() {
      this.getDataList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.screen = {
        ...defaultQuery
      }
      this.addressData = []
      this.registrationTime = []
      this.search();
    },
    /**
     * 获取表格列表数据
     */
    getDataList() {
      this.tableData.loading = true;
      this.$api.companyLists(this.screen).then(res => {
        this.$set(this.tableData, 'data', res.data.data);
        this.$set(this.tableData, 'total', res.data.count);
      }).finally(() => {
        this.tableData.loading = false;
      })
    },
    editList(scorp, type) {
      if (type === 'report') {
        this.$router.push({path: '/diagnosticDetail', query: {id: scorp.row.id, is_status: 1}})
      } else if (type == 'edit') {
		  this.$router.push({path: '/edit', query: {id: scorp.row.id}})
	  } else {
        this.$router.push({path: '/editList', query: {company_id: scorp.row.id}})
      }
    },
    handleChange(value) { //选择地区之后获取网格-
      this.screen.areaid = null;
      if (value) {
        // this.screen.areaid = value[2];
        this.screen.areaid = value.map(item => item[item.length-1])
      }
    },
    handleChangeDate(value) { //选择时间之后获取网格-
      this.screen.start_time = null;
      this.screen.end_time = null;
      if (value) {
        this.screen.start_time = value[0];
        this.screen.end_time = value[1];
      }
    },
    inviteConsultation(scrop) {//邀请问诊
      // let id = scrop.row.id
      // let idString = id.toString();
      // let data = {
      //   company_id:[idString],
      //   queid:'1',
      //   type:'1'
      // }
      // this.$api.company_invite(data).then(res=>{
      //   console.log(res)
      // })
    }
  }
}
</script>

<style lang="scss" scoped src="@/styles/common.scss"></style>
<style lang="scss" scoped>
.enterprise-list {
  margin: 18px;
  padding: 30px 20px;
  background: #FFFFFF;
  .el-table {
    .color-red {
      color: #FD5451;
    }

    .color-green {
      color: #04CD83;
    }

    .color-blue {
      color: #3984F5;
    }
  }

  .operate {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .handle {
      display: flex;

      .all-select {
        margin-right: 16px;
        padding-left: 24px;
        line-height: 32px;
      }

      .button {
        margin-right: 10px;
        padding: 0 12px;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        color: #6C7880;
        border: 1px solid #CEDAE0;
        cursor: pointer;

        &:hover {
          color: #3984F5;
          border-color: #3984F5;
        }
      }
    }
  }
}

// 输入框样式修改
::v-deep .el-input {
  font-size: 12px;

  .el-input__inner {
    padding: 0 12px;
    height: 34px;
    line-height: 34px;
    border: 1px solid #CEDAE0;
    color: #242D33;
  }
}

// 下拉框样式修改
::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: #CEDAE0;
  }

  .el-input__icon {
    font-size: 12px;
    line-height: 34px;
  }
}

// 下拉悬浮框样式修改
::v-deep .el-select-dropdown {
  border-color: #CEDAE0;
  box-shadow: 0 0 12px rgba(36, 45, 51, 0.15);

  .el-select-dropdown__item {
    font-size: 12px;
    color: #242D33;
  }

  .el-select-dropdown__item.hover {
    background-color: transparent;
  }

  .el-select-dropdown__item:hover {
    background-color: #F6F7F9;
  }

  .el-select-dropdown__item.selected {
    color: #3984F5;
    font-weight: 400;
    background-color: #F6F7F9;
  }

  .el-select-dropdown__empty {
    font-size: 12px;
  }
}

// 日期样式修改
::v-deep .el-date-editor {
  padding: 0 12px;
  height: 34px;
  line-height: 34px;
  border: 1px solid #CEDAE0;
  color: #242D33;

  .el-range-input {
    width: 38%;
    font-size: 12px;
    color: #242D33;
  }

  .el-range-separator {
    width: 6%;
    font-size: 12px;
  }

  .el-range__close-icon {
    margin-right: -5px;
    width: 18px;
  }
}

// 表格样式修改
::v-deep .el-table {
  font-size: 12px;
  color: #242D33;
  border-color: #DCE5EC;

  &::before,
  &::after {
    background-color: #DCE5EC;
  }

  .el-table__cell {
    padding: 8px 0;
    border-color: #DCE5EC;
  }

  .cell {
    line-height: 24px;
  }

  thead {
    color: #242D33;
  }

  th.el-table__cell.is-leaf {
    border-color: #DCE5EC;
    background: #F6F7F9;
  }

  th {
    font-weight: 400;
  }

  .el-table-column--selection .cell {
    padding-right: 10px;
  }

  .el-table__body tr:hover > td.el-table__cell {
    background-color: #F6FBFE;
  }

  td.el-table__cell {
    padding: 13px 0;
  }
}

// 分页样式修改
::v-deep .el-pagination {
  padding: 0;
  color: #242D33;
  font-weight: 400;

  button {
    font-size: 12px;
    height: 32px;
    line-height: 32px;
  }

  .el-pager li {
    font-size: 12px;
    height: 32px;
    line-height: 32px;
  }

  .btn-next, .btn-prev, .el-pager li {
    border: 1px solid #CEDAE0;
    background-color: #ffffff;
    color: #242D33;
    min-width: 32px;
  }

  .btn-next:not(:disabled), .btn-prev:not(:disabled), .el-pager li {
    &:hover {
      color: #3984F5;
      border-color: #3984F5;
    }
  }

  .el-pager li:not(.disabled).active {
    background-color: #3984F5;
    border-color: #3984F5;
  }

  .btn-next.disabled, .btn-next:disabled, .btn-prev.disabled, .btn-prev:disabled, .el-pager li.disabled {
    color: #E1E8EC;
  }

  .el-pagination__sizes {
    margin-right: 0;
    height: 32px;
    line-height: 32px;
  }

  .el-select .el-input {
    margin-right: 0;

    .el-input__inner {
      font-size: 12px;
      height: 32px;
      line-height: 32px;

      &:hover {
        border-color: #3984F5;
      }
    }
  }

  .el-range-editor.is-active, .el-range-editor.is-active:hover, .el-select .el-input.is-focus .el-input__inner {
    border-color: #3984F5;
  }

  .touch-hover {
    cursor: pointer;
  }
}
</style>
