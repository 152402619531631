<template>
	<div class="invite-popup-main">
		<el-dialog title="邀请问诊" :visible="show" width="720px" :close-on-press-escape="false" :before-close="handleClose" @close="close" @open="openClick">
			<p class="title-text">第一步：选择企业需要填写的问卷</p>
			<el-select v-model="querValue" placeholder="请选择问卷" @change="selectClick">
			    <el-option v-for="item in allData" :key="item.id" :label="item.title" :value="item.id"> </el-option>
			</el-select>
			<p class="title-text">第二步：选择邀请方式</p>
			<div class="type-content flex flex-col-center flex-row-between">
				<div class="type-left">
					<p class="left-title">方式一</p>
					<p class="l-con-text">点击下方按钮发送短信，企业通过短信里的邀请</p>
					<p class="dx-title">短信内容:</p>
					<p class="dx-con-text">【姓名】你好！云南联通诚邀【企业名称】参与填写调研问卷《【问卷名称】》，问卷地址：【问卷地址】，企业专属邀请码：【邀请码】</p>
					<button class="btn-text top-37" @click="subEmail">发送邀请短信</button>
				</div>
				<div class="type-left">
					<p class="left-title">方式二</p>
					<p class="l-con-text">保存下方二维码，发送给企业进行问卷填写</p>
					<img class="cord-img" :src="cordImg[0]" alt="" />
          <div class="mask" v-if="!querValue">请先选择问卷</div>
          <button class="btn-text top-14" v-if="cordImg.length>1" @click="saveImgZip">保存图片到本地</button>
					<button class="btn-text top-14" v-else @click="saveImg">保存图片到本地</button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {downloadImg, fileDownload} from "@/utils/index.js"
	export default{
		name: 'invite-popup',
		props: {
			show: false,
			companyList: {
				type: Array,
				default: []
			}
		},
		data(){
			return{
				allData: [],
				querValue: '',//选择的数据
				cordImg: '',//二维码
        imgZip:null //下载zip地址
			}
		},
		methods:{
			//打开窗口
			openClick() {
				this.$api.companyQuestionnaire().then(res=>{
					this.allData = res.data;
					this.getCord(res.data[0]);
				})
			},
			//获取二维码
			getCord(e) {
				this.$api.company_invite({company_id: this.companyList, queid: e.id, type: 0}).then(res=>{
          console.log(res,147)
					this.cordImg = res.data.qr_url;
          this.imgZip = res.data.zip_url
          console.log(this.cordImg,148)
				})
			},
			//选择问卷
			selectClick(e) {
				this.getCord({id: e});
			},
			//发送短信
			subEmail() {
				if(!this.querValue) return this.$message({type: 'warning', message: '请选择问卷'});
				this.$confirm('是否立即发送邀请短信？', '提示', {confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'}).then(res=>{
					this.$api.company_invite({company_id: this.companyList, queid: this.querValue, type: 1}).then(res=>{
						this.$message({type: 'success', message: '发送成功！'});
					})					
				})
			},
			//保存图片到本地
			saveImg() {
				if (!this.cordImg) return;
        downloadImg(this.cordImg);
			},
      //保存图片合集
      saveImgZip() {
        if (!this.imgZip) return;
        fileDownload(this.imgZip);
      },
			handleClose(done){
				done();
				this.close();
			},
			close() {
				this.$emit('close');
			}
		}
	}
</script>

<style lang="scss" scoped>
	.invite-popup-main{
		.title-text{font-size: 12px;font-weight: bold;color: #242D33;margin-left: 20px;}
		.el-select{width: 310px;height: 34px;line-height: 34px;margin: 10px 0 29px 0;border-radius: 4px;margin-left: 20px;}
		.type-content{margin: 14px 20px 0 20px;
			.type-left{width: 310px;height: 275px;background: #FAFCFE;padding: 20px 18px;box-sizing: border-box;position: relative;
				.left-title{font-size: 12px;font-weight: bold;color: #242D33;}
				.l-con-text{font-size: 12px;color: #242D33;margin-top: 17px;}
				.dx-title{font-size: 12px;font-weight: bold;color: #242D33;margin-top: 29px;}
				.dx-con-text{font-size: 12px;color: #242D33;line-height: 24px;}
				.cord-img{width: 120px;height: 120px;margin: 0 auto;margin-top: 16px;display: block;}
				.btn-text{width: 140px;height: 40px;background: #3984F5;border-radius: 4px;font-size: 14px;color: #fff;line-height: 40px;margin: 0 auto;display: block;}
				.top-37{margin-top: 37px;}
				.top-14{margin-top: 14px;}
        .mask{width: 120px;height: 120px;position: absolute;background: rgba(255,255,255,0.8);color: #242D33;font-weight: bold;display: flex;justify-content: center;align-items: center;left: 95px;top: 89px}
			}
		}
		
		
	}
	
</style>